import React, { useState, useEffect }  from "react";
import CenterBox from '../CenterBox'
import GetMenuItems from '../../requests/GetMenuItems.js'

function UserMenu() {

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    // console.log('useEffect() : (#wecwec)')
    GetMenuItems(setMenuItems);
  }, []);

  const Menu = () => {
    return (
      <ul>
        {menuItems.map(item => {
          console.log('#2wefcwex:', item)
          return (
              <li><a href={item.url}>{item.label}</a></li>
          )
        })}
      </ul>
    )
  }

  return (
    <>
      <CenterBox>
        <h2>User Menu:</h2>
        <Menu />
      </CenterBox>
    </>
  )
}

export default UserMenu