import axios from "axios";

const GetMenuItems = async (setMenuItems) => {
    const requestedURL = 'http://localhost:8001/admin/mainMenu'

    const response = await axios.get(requestedURL)
    const menuItems = response.data.data.menuItems

    setMenuItems(menuItems);
    console.log('Axios result (#wecwec):', menuItems)

    return menuItems;
}

export default GetMenuItems