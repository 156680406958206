import React from "react"

// function UserMenu() {
const CenterBox = ({ children }) => (
  <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
    <div style={{width:'600px'}}>
      { children }
    </div>
  </div>
)
export default CenterBox