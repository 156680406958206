import React  from "react";

// componenents
import Layout from "../components/Layout";
import UserMenu from "../components/UserMenu"

// Todo:
// - Add env vars
// - Configure app to only show dev login values when in dev.

// markup
const IndexPage = () => {
  return (
    <Layout>
      <UserMenu />
    </Layout>
  )
};

export default IndexPage;